<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Wijzig item" />

    <div class="mt-10 sm:mt-0" v-if="!loading">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">General information</h3>
            <p class="mt-1 text-sm text-gray-600">
              Here you can provide the information needed for the item.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <PersonForm :person="data" v-if="data" />
          <button type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" @click="destroy">
            Delete
          </button>
        </div>
      </div>
    </div>

    <div v-if="data">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Photo</h3>
            <p class="mt-1 text-sm text-gray-600">
              Upload a high resolution image. The image will be scaled automatically.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          
            <div class="shadow sm:rounded-md sm:overflow-hidden">
              <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
                
                <div>
                  <Uploader model="Person" :model_id="data.id" @update-add="myMethod" />  
                  <PhotoList model="Person" :photos="data.Images" :id="data.id" @update-delete="myMethod" />
                </div>
              </div>
              
            </div>
          
        </div>
      </div>

      

    </div>

  </main>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from 'vue-router'
import PeopleService from '../../../services/admin/people/service';
import PersonForm from '../../../components/admin/people/PersonForm.vue';
import PhotoList from '../../../components/admin/generic/PhotoList';
import Uploader from '../../../components/admin/generic/Uploader';


export default {
  name: 'Person',
  components: {
    PersonForm,
    Uploader,
    PhotoList
  },
  setup() {
    const data = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const route = useRoute()
    const id = route.params.id;

    function fetchData() {
      loading.value = true;
      // I prefer to use fetch
      // you can use use axios as an alternative
      return PeopleService.getPerson(id).then(res => {
          // a non-200 response code
          if (res.status !== 200) {
            // create error instance with HTTP status text
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }

          return res.data;
        })
        .then(json => {
          // set the response data
          data.value = json;
        })
        .catch(err => {
          error.value = err;
          // In case a custom JSON error response was provided
          if (err.json) {
            return err.json.then(json => {
              // set the JSON response message
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
        });
    }
    onMounted(() => {
      fetchData();
    });

    return {
      data,
      loading,
      error
    };
  },
  methods: {
    async myMethod() {
      const {data} = await PeopleService.getPerson(this.$route.params.id);  
      this.data = data;
    },
    async destroy() {
      if(confirm('are you sure?')) {
        PeopleService.delete(this.data.id)
        this.$router.push('/people');
      }
    }
  }
}
</script>